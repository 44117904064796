import { createPlanClick, ppMiscClick, pricingPlansInstallationStage } from '@wix/bi-logger-membership/v2';
import { maybeInstallMembersArea, MA_APP_IDS, withMembersArea } from '@wix/members-area-integration-kit';
import {
  EditorPlatformApp,
  JsonObject,
  ComponentRef,
  PageRef,
  ContextParams,
  EditorSDK,
  EventType,
  InstallationOriginType,
} from '@wix/platform-editor-sdk';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import type { EditorReadyFn, EditorScriptFlowAPI, GetAppManifestFn, PagesBuilder } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import pricingPlans from '../.application.json';
import { addPlanListWidget } from './blocks-widgets-editor/plan-list';
import {
  addSinglePlanWidget,
  onGlobalDesignPresetChanged,
  onSinglePlanWidgetAddedToStage,
  setStateForExistingBlocksWidgets,
} from './blocks-widgets-editor/single-plan';
import { onPerkTextStyleChange } from './components/Benefits/layout-utils';
import checkout from './components/Checkout/.component.json';
import packagePicker from './components/PackagePicker/.component.json';
import paywall from './components/Paywall/.component.json';
import planList from './components/PlanList/.component.json';
import { updateCurrencyMargins } from './components/Pricing/panels/Layout/utils';
import thankYou from './components/ThankYou/.component.json';
import { SinglePlanWidgetRole } from './constants/elements';
import { createMemebersAreaInstallLogger } from './services/membersAreaInstallLogger';
import type { PrivatePricingPlansApi, PublicPricingPlansApi } from './types/editor';
import { captureException, suggestNoComponentsFix, suggestNoMainPageFix } from './utils/editor';
import { setPageStates, splitPagesMigration } from './utils/installPages';
import { installRouter, suggestMigratingToRouter } from './utils/installRouter';
import { setPermissionsPage } from './utils/setPermissionsPage';
import {
  isSinglePlanWidgetRef,
  componentHasRole,
  getParentWidget,
  getCurrentPresetId,
  getContainerRef,
  getPlanWidget,
  isContainerRef,
} from './utils/widget';

const TOKEN = '';
const appDefinitionId = pricingPlans.appDefinitionId;

const AUTOPILOT_USER_ID = '6316f5bb-5858-4345-92ed-04566c1d7f54';
const ACTION_APP_INSTALLED = 'appInstalled';
const INSTALLATION_TYPE_AUTOPILOT = 'autopilot';
const INSTALLATION_TYPE_REGULAR = 'regular';

type Context = {
  editorSDK: EditorSDK;
  flowAPI: EditorScriptFlowAPI;
  contextParams: ContextParams;
};

const { setContext, getContext } = (() => {
  let ctx: Context;
  return {
    setContext: (_ctx: Context) => {
      ctx = _ctx;
    },
    getContext: () => ctx,
  };
})();

const isAutopilotUser = async (editorSDK: EditorSDK) =>
  (await editorSDK.info.getUserInfo(TOKEN)).userId === AUTOPILOT_USER_ID;

const reportPricingPlansInstallationStage = async (
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
  stage: string,
) => {
  flowAPI.errorMonitor.addBreadcrumb({ message: 'PP reportPricingPlansInstallationStage', data: { stage } });
  const isAutopilot = await isAutopilotUser(editorSDK);
  flowAPI.bi?.report(
    pricingPlansInstallationStage({
      appId: appDefinitionId,
      appInstanceId: await editorSDK.info.getAppInstanceId(TOKEN),
      biToken: await editorSDK.info.getMetaSiteId(TOKEN),
      installationType: isAutopilot ? INSTALLATION_TYPE_AUTOPILOT : INSTALLATION_TYPE_REGULAR,
      stage,
    }),
  );
};

let globalListenersAdded = false;

async function onAnyComponentAddedToStage(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  flowAPI.errorMonitor.addBreadcrumb({ message: 'PP onAnyComponentAddedToStage' });
  if (await isSinglePlanWidgetRef(editorSDK, componentRef)) {
    onSinglePlanWidgetAddedToStage({ editorSDK, refComponent: componentRef, flowAPI });
  }
}

const editorReadyInternal: EditorReadyFn = async (editorSDK, token, contextParams, flowAPI) => {
  flowAPI.fedops.interactionStarted('es_editor_ready');
  flowAPI.errorMonitor.addBreadcrumb({ message: 'PP editorReadyInternal' });

  try {
    const data = await editorSDK.document.tpa.app.getDataByAppDefId(TOKEN, appDefinitionId);
    const components = await editorSDK.document.tpa.app.getAllCompsByApplicationId(TOKEN, data.applicationId);
    const mainPage = components?.find((c) => c.type === 'TPA') ?? null;
    if (components === null) {
      flowAPI.errorMonitor.captureMessage('There are no Components for app');
      suggestNoComponentsFix(flowAPI, editorSDK);
    } else if (mainPage === null) {
      flowAPI.errorMonitor.captureMessage('There is no Main Page Component for app');
      suggestNoMainPageFix(flowAPI, editorSDK);
    }
  } catch (e) {
    captureException(flowAPI, e);
  }

  try {
    // On first install permission page will be set by appInstalled event handler.
    if (!contextParams.firstInstall) {
      await trySetPermissionsPage(editorSDK, flowAPI);
      if (flowAPI.experiments.enabled(TPA_EXPERIMENTS.BLOCKS_SET_CONTROLLER_STATE_ON_LOAD)) {
        setStateForExistingBlocksWidgets(editorSDK);
      }
    }

    if (flowAPI.experiments.enabled(TPA_EXPERIMENTS.INSTALL_SPLIT_PAGES)) {
      if (contextParams.firstInstall) {
        await splitPagesMigration(flowAPI, editorSDK);
        await installRouter(editorSDK, flowAPI.experiments.enabled(TPA_EXPERIMENTS.ROUTE_PAYWALL));
      } else if (
        (await editorSDK.routers.getAll('')).length === 0 &&
        flowAPI.experiments.enabled(TPA_EXPERIMENTS.ROUTER_MIGRATION)
      ) {
        await suggestMigratingToRouter(flowAPI, editorSDK);
      }
      await setPageStates(editorSDK);

      editorSDK.addEventListener(EventType.appVisitedInDashboard, async () => {
        await editorSDK.editor.routers.refresh(TOKEN);
        await editorSDK.tpa.app.refreshApp(TOKEN);
      });
    }

    // Checking if handlers were already set, because there's a bug in editor,
    // where editorReady is called again when blocks widget is added through Add Panel
    if (!globalListenersAdded) {
      globalListenersAdded = true;
      // Event is used to initialize the widget when it is added through Add Panel
      editorSDK.addEventListener(EventType.anyComponentAddedToStage, (e) =>
        onAnyComponentAddedToStage({ editorSDK, flowAPI, componentRef: e.detail.compRef }),
      );
      editorSDK.addEventListener(EventType.componentStyleChanged, async (e) => {
        const { compRef: componentRef } = e.detail;
        if (await componentHasRole({ editorSDK, componentRef, role: SinglePlanWidgetRole.PerkText })) {
          onPerkTextStyleChange(editorSDK, componentRef);
        } else if (await componentHasRole({ editorSDK, componentRef, role: SinglePlanWidgetRole.Price })) {
          const widgetRef = await getParentWidget({ editorSDK, componentRef });
          if (widgetRef) {
            updateCurrencyMargins({ editorSDK, priceRef: componentRef, widgetRef });
          }
        } else if (await componentHasRole({ editorSDK, componentRef, role: SinglePlanWidgetRole.Currency })) {
          const widgetRef = await getParentWidget({ editorSDK, componentRef });
          if (widgetRef) {
            updateCurrencyMargins({ editorSDK, currencyRef: componentRef, widgetRef });
          }
        }
      });
    }

    flowAPI.fedops.interactionEnded('es_editor_ready');
  } catch (e) {
    captureException(flowAPI, e, { print: true });
  }
};

const handleActionInternal: EditorPlatformApp['handleAction'] = async ({ type, payload }, editorSDK) => {
  const { flowAPI } = getContext();
  flowAPI.fedops.interactionStarted('es_handle_action');
  flowAPI.errorMonitor.addBreadcrumb({ message: 'PP handleActionInternal', data: { type } });
  try {
    if (type === ACTION_APP_INSTALLED && (payload as Record<string, string>).appDefinitionId === appDefinitionId) {
      await reportPricingPlansInstallationStage(editorSDK, flowAPI, ACTION_APP_INSTALLED);
      await trySetPermissionsPage(editorSDK, flowAPI);
    }
    flowAPI.fedops.interactionEnded('es_handle_action');
  } catch (e) {
    captureException(flowAPI, e, { print: true });
  }
};

const trySetPermissionsPage = async (editorSDK: EditorSDK, flowAPI: EditorScriptFlowAPI) => {
  flowAPI.fedops.interactionStarted('es_set_permissions_page');
  flowAPI.errorMonitor.addBreadcrumb({ message: 'PP trySetPermissionsPage' });
  try {
    await setPermissionsPage(editorSDK, flowAPI, appDefinitionId);
    flowAPI.fedops.interactionEnded('es_set_permissions_page');
  } catch (e) {
    captureException(flowAPI, e, { print: true });
  }
};

const platformApp = withMembersArea(
  {
    editorReady: editorReadyInternal,
    handleAction: handleActionInternal,
  },
  {
    installAutomatically: false,
    disableADI: false,
    membersAreaApps: [MA_APP_IDS.MY_SUBSCRIPTIONS, MA_APP_IDS.MY_WALLET],
    onEvent: createMemebersAreaInstallLogger({
      fedopsLogger: () => {
        const { flowAPI } = getContext();
        return flowAPI.fedops;
      },
      isAutopilotUser: () => {
        const { editorSDK } = getContext();
        return isAutopilotUser(editorSDK);
      },
      reportPricingPlansInstallationStage: async (stage) => {
        const { editorSDK, flowAPI } = getContext();
        return reportPricingPlansInstallationStage(editorSDK, flowAPI, stage);
      },
    }),
    reportErrors: true,
  },
);

export const editorReady: EditorReadyFn = async (editorSDK, token, contextParams, flowAPI) => {
  setContext({
    editorSDK,
    flowAPI,
    contextParams,
  });
  flowAPI.fedops.interactionStarted('es_editor_ready_external');
  flowAPI.errorMonitor.addBreadcrumb({ message: 'PP editorReady' });
  try {
    // @ts-expect-error
    await platformApp.editorReady(editorSDK, token, contextParams, flowAPI);
    flowAPI.fedops.interactionEnded('es_editor_ready_external');
  } catch (e) {
    captureException(flowAPI, e, { print: true });
  }
};

const handleAction: EditorPlatformApp['handleAction'] = async (action, editorSDK) => {
  const { flowAPI } = getContext();
  flowAPI.fedops.interactionStarted('es_handle_action_external');
  flowAPI.errorMonitor.addBreadcrumb({ message: 'PP handleAction', data: { type: action.type } });
  try {
    // @ts-expect-error
    await platformApp.handleAction(action, editorSDK);
    flowAPI.fedops.interactionEnded('es_handle_action_external');
  } catch (e) {
    captureException(flowAPI, e, { print: true });
  }
};

export const getAppManifest: GetAppManifestFn = async (options, editorSDK, contextParams, flowAPI) => {
  setContext({
    editorSDK,
    flowAPI,
    contextParams,
  });
  flowAPI.errorMonitor.addBreadcrumb({ message: 'PP getAppManifest' });
  const t = flowAPI.translations.t;

  options.appManifestBuilder
    .configureWidget(planList.id, (builder) => {
      builder.set({ displayName: t('manifest.plan-list-widget.display-name') });
    })
    .configureManagementActions((builder) => {
      builder.mainActions().addAction(
        {
          title: t('manage-apps-panel.manage-plans'),
          actionId: 'openManagePlans',
          icon: 'appManager_settingsAction',
        },
        {
          title: t('manage-apps-panel.create-new-plan'),
          actionId: 'createNewPlan',
          icon: 'appManager_addElementsAction',
        },
      );

      builder.customActions().addAction(
        {
          title: t('manage-apps-panel.manage-purchases'),
          actionId: 'openManagePurchases',
          icon: 'appManager_settingsAction',
          type: 'dashboard',
        },
        {
          title: t('manage-apps-panel.customize-plans-page'),
          icon: 'appManager_pagesAction',
          actionId: 'openPricingPlansSettings',
          type: 'editorActions',
        },
        {
          title: t('manage-apps-panel.add-pricing-plans-elements'),
          icon: 'appManager_addElementsAction',
          actionId: 'addPricingPlansElements',
          type: 'editorActions',
        },
        {
          title: t('manage-apps-panel.manage-email-automations'),
          icon: 'email_icon',
          actionId: 'openMyAutomations',
          type: 'dashboard',
        },
      );

      builder.learnMoreAction().set({ id: 'b1d66914-9073-43d1-af55-f923aac621b0' });

      builder.upgradeAction().set({
        upgradeType: 'SITE_UPGRADE',
        upgradeText: t('manage-apps-panel.upgrade-text'),
        upgradeLinkText: t('manage-apps-panel.upgrade-link-text'),
      });
    });

  if (flowAPI.experiments.enabled(TPA_EXPERIMENTS.INSTALL_SPLIT_PAGES)) {
    const managePlansGfpp = {
      label: t('es.gfpp.manage_plans'),
      onClick() {
        return editorSDK.editor.openDashboardPanel(TOKEN, {
          url: '/pricing-plans?referralInfo=gfpp',
          closeOtherPanels: true,
        });
      },
    };

    options.appManifestBuilder
      .configureWidget(checkout.id, (builder) =>
        builder.gfpp().set('mainAction1', managePlansGfpp).set('mainAction2', { behavior: 'HIDE' }),
      )
      .configureWidget(thankYou.id, (builder) =>
        builder.gfpp().set('mainAction1', managePlansGfpp).set('mainAction2', { behavior: 'HIDE' }),
      )
      .configureWidget(paywall.id, (builder) =>
        builder.gfpp().set('mainAction1', managePlansGfpp).set('mainAction2', { behavior: 'HIDE' }),
      )
      .configureWidget(planList.id, (builder) => builder.gfpp().set('mainAction1', managePlansGfpp))
      .configureWidget(packagePicker.id, (builder) => builder.gfpp().set('mainAction1', managePlansGfpp))
      .configurePagesTab((pagesTabBuilder) => {
        pagesTabBuilder.set({ displayName: t('es.pages.group.title'), helpId: '' });
        pagesTabBuilder
          .addAction({
            icon: 'settingsAction',
            title: t('es.pages.group.actions.manage'),
            onClick() {
              editorSDK.editor.openDashboardPanel(TOKEN, {
                url: '/pricing-plans?referralInfo=site_pages_group_panel',
                closeOtherPanels: true,
              });
            },
          })
          .addAction({
            icon: 'delete_icon',
            title: t('es.pages.group.actions.delete'),
            onClick() {
              editorSDK.application.uninstall(TOKEN, { openConfirmation: true });
            },
          });
        pagesTabBuilder.addReplaceablePage('membership_plan_picker_tpa');
      })
      .configurePages((pagesBuilder) => {
        pagesBuilder
          .set({ orderIndex: 4, icon: 'page' })
          .addAction({ type: 'page_rename' }, { type: 'page_set_as_homepage' }, { type: 'page_delete' })
          .addSettingsTab(
            { title: t('es.pages.settings-tab.info.title'), type: 'page_info' },
            { title: t('es.pages.settings-tab.layout.title'), type: 'layout' },
            { title: t('es.pages.settings-tab.permissions.title'), type: 'permissions' },
            { title: t('es.pages.settings-tab.seo.title'), type: 'seo' },
          );

        const configureInternalPage =
          (
            componentName: 'Checkout' | 'ThankYou' | 'Paywall',
            orderIndex: number,
          ): Parameters<PagesBuilder['configureState']>[1] =>
          (stateBuilder) =>
            stateBuilder
              .set({ orderIndex, icon: 'page' })
              .addAction({ type: 'page_rename' })
              .addSettingsTab(
                {
                  title: t('es.pages.settings-tab.info.title'),
                  type: 'page_info',
                  url: getPanelUrl(componentName, 'PageInfo'),
                },
                { title: t('es.pages.settings-tab.layout.title'), type: 'layout' },
              );

        pagesBuilder
          .configureState('paywall', configureInternalPage('Paywall', 3))
          .configureState('checkout', configureInternalPage('Checkout', 2))
          .configureState('thank_you', configureInternalPage('ThankYou', 1));
      });
  }

  return options.appManifestBuilder.build();
};

export const onEvent: EditorPlatformApp['onEvent'] = async ({ eventType, eventPayload }, editorSDK) => {
  const { flowAPI } = getContext();
  flowAPI.errorMonitor.addBreadcrumb({ message: 'PP onEvent', data: { eventType } });
  if (eventType === 'instanceChanged') {
    flowAPI.setHttpClientToken(eventPayload.instance);
  } else if (eventType === 'appActionClicked') {
    const { actionId } = eventPayload;
    flowAPI.bi?.report(
      ppMiscClick({
        referralInfo: 'appAction_' + actionId,
      }),
    );
    if (actionId === 'openManagePlans') {
      await editorSDK.editor.openDashboardPanel(TOKEN, {
        url: '/pricing-plans?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    } else if (actionId === 'createNewPlan') {
      flowAPI.bi?.report(
        createPlanClick({
          appInstanceId: await editorSDK.info.getAppInstanceId(TOKEN),
          biToken: await editorSDK.info.getMetaSiteId(TOKEN),
          formOrigin: 'manage_app_panel',
          referralInfo: 'manage_app_panel',
          origin: 'editor',
        }),
      );
      await editorSDK.editor.openDashboardPanel(TOKEN, {
        url: '/pricing-plans/new?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    } else if (actionId === 'openManagePurchases') {
      await editorSDK.editor.openDashboardPanel(TOKEN, {
        url: '/subscriptions/?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    } else if (actionId === 'openPricingPlansSettings') {
      await privateApi.openSettings();
    } else if (actionId === 'addPricingPlansElements') {
      await privateApi.openAddons(true);
    } else if (actionId === 'openMyAutomations') {
      await editorSDK.editor.openDashboardPanel(TOKEN, {
        url: '/triggers/?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    }
  } else if (eventType === 'globalDesignPresetChanged') {
    onGlobalDesignPresetChanged({ eventPayload, editorSDK, flowAPI });
  }
};

const privateApi: PrivatePricingPlansApi = {
  async openAddons(focusPackagePicker = false) {
    const { editorSDK, flowAPI } = getContext();
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP privateApi.openAddons' });
    const { openAddonsModal } = await import('./utils/settings');
    await openAddonsModal(editorSDK, flowAPI, focusPackagePicker);
  },
  async openSettings() {
    const { editorSDK, flowAPI } = getContext();
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP privateApi.openSettings' });
    const { openPricingPlansSettings } = await import('./utils/settings');
    await openPricingPlansSettings(editorSDK, flowAPI.bi);
  },
  async addPlanListWidget(pageId: string) {
    const { editorSDK, flowAPI } = getContext();
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP privateApi.addPlanListWidget' });
    const { addPlanListWidgetToStage } = await import('./utils/components');
    await addPlanListWidgetToStage(editorSDK, pageId);
  },
  async addSinglePlanBlocksWidget(pageId: string, planId: string) {
    const { editorSDK, flowAPI } = getContext();
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP privateApi.addSinglePlanBlocksWidget' });
    const pageRef = { id: pageId, type: 'DESKTOP' } as PageRef;
    await editorSDK.pages.navigateTo('', { pageRef });
    await addSinglePlanWidget({ editorSDK, flowAPI, planId });
  },
  async addPlanListBlocksWidget(pageId: string, planIds?: string[]) {
    const { editorSDK, flowAPI } = getContext();
    flowAPI.errorMonitor.addBreadcrumb({ message: 'PP privateApi.addPlanListBlocksWidget' });
    const pageRef = { id: pageId, type: 'DESKTOP' } as PageRef;
    await editorSDK.pages.navigateTo('', { pageRef });
    await addPlanListWidget({ editorSDK, flowAPI, planIds });
  },
};

const publicApi: PublicPricingPlansApi = {
  async setDemoData(plans) {
    const { editorSDK } = getContext();
    const { applicationId } = await editorSDK.document.tpa.app.getDataByAppDefId(TOKEN, appDefinitionId);
    const components = await editorSDK.tpa.app.getAllCompsByApplicationId(TOKEN, applicationId);

    if (components.length) {
      await editorSDK.tpa.data.set(TOKEN, {
        compRef: { id: components[0].id, type: 'DESKTOP' },
        key: 'demoData',
        scope: 'APP',
        value: { plans } as unknown as JsonObject,
      });
    } else {
      throw new Error('No Pricing Plans components found on site');
    }
  },
};

export const exports: EditorPlatformApp['exports'] = (editorSDK, contextParams) => {
  return {
    private: privateApi,
    public: publicApi,
    editor: {
      getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
        const container = (await isContainerRef(editorSDK, widgetRef))
          ? widgetRef
          : await getContainerRef(editorSDK, widgetRef);
        const planWidget = await getPlanWidget(editorSDK, container);
        return getCurrentPresetId(editorSDK, planWidget);
      },
      async appInstalled(data) {
        const { flowAPI } = getContext();
        flowAPI.fedops.interactionStarted('app_installed_event');
        flowAPI.errorMonitor.addBreadcrumb({ message: 'PP editor.appInstalled' });

        const message = await Promise.race([
          new Promise<void>(async (resolve) => {
            await handleAction(
              {
                type: 'appInstalled',
                payload: { appDefinitionId: data.appDefinitionId, biData: contextParams.biData },
              },
              editorSDK,
            );
            resolve();
          }),
          new Promise<string>((resolve) =>
            setTimeout(() => {
              resolve(`handleAction on appInstalled (appDefinitionId: ${data.appDefinitionId}) is stuck`);
            }, 60000),
          ),
        ]);
        if (message) {
          flowAPI.errorMonitor.captureMessage(message);
        }

        const installMembersArea =
          contextParams.firstInstall &&
          data.appDefinitionId === appDefinitionId &&
          contextParams.origin?.info?.type !== InstallationOriginType.SILENT_INSTALL_SITE_CREATION &&
          contextParams.origin?.info?.type !== InstallationOriginType.SILENT_INSTALL;

        if (installMembersArea) {
          flowAPI.fedops.interactionStarted('es_maybe_install_members_area');
          try {
            await maybeInstallMembersArea({ biData: contextParams.biData });
            flowAPI.fedops.interactionEnded('es_maybe_install_members_area');
          } catch (e) {
            captureException(flowAPI, e);
          }
        }
        flowAPI.fedops.interactionEnded('app_installed_event');
      },
      async removeApp() {
        const { flowAPI } = getContext();
        flowAPI.fedops.interactionStarted('remove_app_event');
        flowAPI.errorMonitor.addBreadcrumb({ message: 'PP editor.removeApp' });
        await handleAction({ type: 'removeApp', payload: {} }, editorSDK);
        flowAPI.fedops.interactionEnded('remove_app_event');
      },
    },
  };
};
